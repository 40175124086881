import Field from "../Types/Field";
import { Control } from "./Control";
import {Board} from "../Types/Board";
import {Coordinates} from "../Types/Coordinates";

interface Props {
    activeField: number
    setActiveField: React.Dispatch<React.SetStateAction<number>>
    toggleField: React.Dispatch<React.SetStateAction<Board | undefined>>
    // selectedField: number
    // setSelectedField: React.Dispatch<React.SetStateAction<number>>
    team: string,
    round: string
}


function handleShoot(
    activeField: number,
    field: Field,
    idx: number,
    setActiveField: React.Dispatch<React.SetStateAction<number>>
) {
    const previous = localStorage.getItem("last-active");

    if (idx === activeField) {
        const position: Coordinates = {
            x: idx % 10,
            y: Math.floor(idx / 10),
            team: Number(localStorage.getItem("team"))
        };
        if (!previous || parseInt(previous) !== idx) {
            // HTTP POST request instead of socket emit
            fetch(`${process.env.REACT_APP_BACKEND_URL}/api/position`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "accept": "allow-credentials",
                    "id": localStorage.getItem("id")!!
                },
                credentials: "include",  // This ensures that cookies are sent with the request
                body: JSON.stringify(position)
            })
                .then(response => {
                    if (response.ok) {
                        console.log("Position sent successfully");
                    } else {
                        console.error("Failed to send position");
                    }
                })
                .catch(error => console.error("Error during fetch:", error));

            localStorage.setItem("last-active", idx.toString());
            setActiveField(idx);
        }

        // const isSelected = !field.selected;
        return { ...field, selected: true };
    }

    return field;
}

export const Controller: React.FC<Props> = ({activeField, setActiveField, toggleField, team, round}) => {
    const moveUp = () => { setActiveField(curr => curr >= 10 ? curr - 10 : curr) }
    const moveDown = () => { setActiveField(curr => curr < 90 ? curr + 10 : curr) }
    const moveLeft = () => { setActiveField(curr => curr % 10 !== 0 ? curr - 1 : curr) }
    const moveRight = () => { setActiveField(curr => curr % 10 !== 9 ? curr + 1 : curr) }
    const toggleShoot = () => { toggleField(curr => ({
        fields: curr?.fields
            .map(field => ({...field, selected: false}))
            .map((field, idx) => handleShoot(activeField, field, idx, setActiveField))
    } as Board)
    )}

    console.log("team", team, "round", round);
    
    const isPlayerRound: boolean = team == round;

    const color: string = team=="1" ? "RebeccaPurple" : team=="2" ? "Orange" : "SeaGreen";

   return (
    <div className="grid grid-cols-3 grid-rows-3 gap-2 p-1 aspect-[1/1]">
        <Control enabled={isPlayerRound} id="btn-1" color={color} move={moveUp}>bi-caret-up</Control>
        <Control enabled={isPlayerRound} id="btn-2" color={color} move={moveLeft}>bi-caret-left</Control>
        <Control enabled={isPlayerRound} id="shoot" color={color} move={toggleShoot}>bi-crosshair</Control>
        <Control enabled={isPlayerRound} id="btn-3" color={color} move={moveRight}>bi-caret-right</Control>
        <Control enabled={isPlayerRound} id="btn-4" color={color} move={moveDown}>bi-caret-down</Control>
    </div>
   )
}