import React from "react";
import Cell from "./Cell";
import Field from "../Types/Field";
import '../Style.css'
import {Board} from "../Types/Board";

interface Props {
    board: Board | undefined
    activeField: number
}

const Map = ({board, activeField}: Props) => {
    if(!board) return (<></>);
    return (
        <div className="grid grid-rows-10 grid-cols-10 gap-2 p-1 h-full m-auto aspect-[1/1]">
            {board.fields.map((field, idx) => {
                const hovering: string = idx === activeField ? "hovering" : "";
                return <Cell classes={[hovering]} field={field} />
            })}
        </div>
    );
}

export default Map;