import ControlCallback from "../Types/ControlCallback";
import 'bootstrap-icons/font/bootstrap-icons.css';

interface Props {
    move: ControlCallback,
    children: string,
    id: string,
    enabled: boolean,
    color: string,
}

export const Control: React.FC<Props> = ({move, children, id, enabled, color}) => {
    return (<button id={id} disabled={!enabled} className="border-solid border-2 rounded-md border-sky-500" onClick={move}><i className={"bi " + children} style={{fontSize: "4rem", color: color}}></i></button>);
}