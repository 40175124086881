export enum FieldType {
    water,
    emptyWater,
    hit ,
    kill,
}
interface Field{
    type: FieldType;
    selected?: boolean
}

export function getColorForField(field: Field): string {
    if(field.selected) return `${process.env.REACT_APP_SELECTED_COLOR}`;
    if (field.type === FieldType.hit) {
        console.log(field.type);
    }
    switch(field.type){
        case FieldType.water: return `${process.env.REACT_APP_WATER_COLOR}`;
        case FieldType.emptyWater: return `${process.env.REACT_APP_EMPTY_WATER_COLOR}`;
        case FieldType.hit: return `${process.env.REACT_APP_HIT_COLOR}`;
        case FieldType.kill: return `${process.env.REACT_APP_KILL_COLOR}`
    }
}

export default Field;