import React, { useEffect, useState } from "react";
import "./App.css";
import "./Style.css";
//import {socket} from "./websocket/socket";
//import {Controller} from "./Components/Controller";
import Field, { FieldType } from "./Types/Field";
import Map from "./Components/Map";
import { Controller } from "./Components/Controller";
import { Board } from "./Types/Board";
import uuid from "react-uuid";
import {
  useQuery,
} from "@tanstack/react-query";
import axios from "axios";

function Game() {
  function getType(num: number) {
    switch (num) {
      case 0:
        return FieldType.water;
      case 1:
        return FieldType.emptyWater;
      case 2:
        return FieldType.hit;
      case 3:
        return FieldType.kill;
      case 5:
        return FieldType.water;
    }
  }

  const [activeField, setActiveField] = useState(0); // Melyiken áll a kurzor

  const [selectedField, setSelectedField] = useState(0); // Hová akar lőni
  const [boardState, setBoardState] = useState<Board>();

  const [isRound, setIsRound] = useState("1");
  const [team, setTeam] = useState("0");

  const query = useQuery({
    queryKey: ["map"],
    queryFn: () => {
      return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/info`);
    },
    refetchInterval: 1000,
    retry: true,
  });
  useEffect(() => {
    if (!query.data)
        return
    const binaryString = atob(query.data?.data.field);
    const uint8Array = new Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }
    const br = {
      fields: uint8Array.map((record) => {
        return {
          type: getType(record),
          selected: false,
        } as Field;
      }) as unknown as Field[],
    } as Board;
    setBoardState(br);

    setIsRound(query.data?.data.current_team)
    
  },[query.data?.data])
  console.log(query.data?.data);

  useEffect(() => {
    if (!localStorage.getItem("team")) {
      const team = Math.floor(Math.random() * 2) + 1;
      console.log("team", team);
      localStorage.setItem("team", String(team));
    }
    setTeam(String(localStorage.getItem("team")));

    let newUuid = localStorage.getItem("id");
    if (!newUuid) {
      newUuid = uuid();
      localStorage.setItem("id", newUuid);
    }

    document.cookie = `id=${newUuid}; path=/; SameSite=Lax;`;

  }, []);

  if (query.isLoading) {
    return <h1>Loading...</h1>;
  }

  if (query.isError) {
    return <h1>Gebasz van...</h1>;
  }



  return (
    <div className="App bg-slate-800 grid grid-cols-1 grid-rows-2 lg:grid-cols-2 lg:grid-rows-1 gap-2 p-4 m-0">
      <Map activeField={activeField} board={boardState} />
      <Controller
        activeField={activeField}
        team={team}
        round={isRound}
        setActiveField={setActiveField}
        toggleField={setBoardState}
      />
    </div>
  );
}
export default Game;
