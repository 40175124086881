import React from "react";
import Field, { getColorForField } from "../Types/Field";
import "../Style.css"

interface CellProps {
    field: Field,
    classes?: string[]
}

const Cell: React.FC<CellProps> = ({field, classes}) => {
    const style = {
        backgroundColor: getColorForField(field)
    }

    const cssClassesStr: string = ["border-0 rounded transition duration-500 ease-in-out", ...classes ?? [""]].join(" ")

    return(
        <div className={cssClassesStr} style={style}>{field.selected}</div>
    )
}

export default Cell;